.App {
}

.App::after {
  content: "";
  background: url('./assets/fingers.png') no-repeat;
  opacity: 0.4;
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background-size:50vmin;
  background-position: bottom right;
  z-index: -1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.App-header {
  /*background-color: #ffffff;*/
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #00046D;
}

.App-language {
  flex-direction: row;
  align-self: flex-end;
  margin-right: 2%;
  margin-top: 1%;
}

.Language-icon {
  color: #00046D;
  vertical-align: middle;
}

.Language-text {
  vertical-align: middle;
  margin-left: 1vmin;
  font-size: calc(7px + 1vmin);
  font-family: "Montserrat";
  color: #00046D;
}

.App-content {
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
  font-size: calc(7px + 2vmin);
  font-family: "Montserrat";
  color: #00046D;
}

.App-bientot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(7px + 2vmin);
  font-family: "Montserrat";
  color: #00046D;
}

.App-link {
  font-family: "Montserrat";
  font-size: calc(7px + 2vmin);
  color: #1A9CD6;
}

a {
  font-family: "Montserrat";
  font-size: calc(7px + 2vmin);
  color: #1A9CD6;
}

b {
  font-family: "Montserrat";
  font-size: calc(1px + 2vmin);
  color: #1A9CD6;
}

a:visited {
  color: #1A9CD6;
}

.social-container {
  /*background: white;*/
  display: flex;
  padding: 25px 50px;
  align-items: center;
  justify-content: center;
}

.contact-container {
  /*background: white;*/
  flex-direction: column;
  display: flex;
  padding: 25px 25px;
  align-items: center;
  justify-content: center;
}

.contact-content {
  font-size: calc(12px);
  font-family: "Montserrat";
  color: grey;
  justify-content:center;
  margin: 3px;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: #00046D;
}

.appstorelogo {
  width: 100%;
}

.playstorelogo {
  width: 100%;
}

.Divider {
  color: #00046D;
  font-size: calc(13px + (32 - 13) * ((100vw - 360px) / (1600 - 360)));
  font-family:'Montserrat';
  font-weight: 'bold';
  padding-top: calc(5px + 2vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
